import type {
  ArticleList,
  AtomLink,
  HtmlBlock,
  MostReadArticleList,
  NewsflowList,
  PersonalizedArticleList,
  RssArticleList,
} from '../../base';
import type { PickTypenamed } from '../../utils/typenamed';

import {
  ExternalLinkB2BTeaserFragment,
  ExternalLinkTeaserFragment,
  TagIntroB2bTeaserFragment,
  TagIntroTeaserFragment,
} from './additional-teasers';
import type {
  ExtraPageTeaserFragment,
  FeatureTeaserFragment,
  InterviewTeaserFragment,
  LiveVideoTeaserFragment,
  NewsArticleTeaserFragment,
  OpinionTeaserFragment,
  PodcastTeaserFragment,
  SponsoredArticleTeaserFragment,
  VideoTeaserFragment,
  VisualStoryTeaserFragment,
} from './article-teasers';
import type { PictureRelationFragment } from './picture-relations';

type ArticleTeaserContent =
  | NewsArticleTeaserFragment
  | FeatureTeaserFragment
  | InterviewTeaserFragment
  | OpinionTeaserFragment
  | SponsoredArticleTeaserFragment
  | VisualStoryTeaserFragment;
type TagIntroTeaserContent = TagIntroTeaserFragment | TagIntroB2bTeaserFragment;

type B2BTeaserContent = TagIntroB2bTeaserFragment | SponsoredArticleTeaserFragment | ExternalLinkB2BTeaserFragment;

export type AdditionalTeaserContent = TagIntroTeaserContent | ExternalLinkTeaserFragment | ExtraPageTeaserFragment;
export type AudioVisualTeaserContent = LiveVideoTeaserFragment | PodcastTeaserFragment | VideoTeaserFragment;
export type RealTeaserContent = ArticleTeaserContent | AudioVisualTeaserContent;

export type OnArticleList = PickTypenamed<ArticleList, 'title'> & {
  id: ArticleList['displayId'];
  content: RealTeaserContent[];
};

export type OnMostReadArticleList = PickTypenamed<MostReadArticleList, 'title' | 'href'> & {
  id: MostReadArticleList['displayId'];
  fields: Pick<
    MostReadArticleList['fields'],
    'maxCount' | 'startFrom' | 'typeSelection' | 'sectionFilter' | 'sectionSelection' | 'premiumStatus' | 'timeFrame'
  >;
  homeSection: PickTypenamed<MostReadArticleList['homeSection'], 'name' | 'href'>;
};
export type OnNewsflowList = PickTypenamed<NewsflowList, 'title'> & {
  content: Array<TeaserFragment<RealTeaserContent>>;
  id: NewsflowList['displayId'];
};

// TODO: CW-62 remove trackingData from TeaserFragment
type TeaserFragmentDefault =
  | AdditionalTeaserContent
  | PersonalizedArticleList
  | RealTeaserContent
  | OnArticleList
  | OnMostReadArticleList
  | OnNewsflowList;

export type TeaserFragment<T = TeaserFragmentDefault> = Pick<
  AtomLink,
  'display' | 'deskedTitle' | 'deskedIntro' | 'deskedLabel'
> & {
  deskedShowLiveIcon: boolean;
  deskedImage?: Array<
    PickTypenamed<AtomLink, 'caption' | 'orientation' | 'display'> & {
      content: PictureRelationFragment;
    }
  >;
  trackingData?: Record<string, unknown>;
  content: T;
};

export function createTeaserFragment(
  partialTeaserFragment: Partial<Omit<TeaserFragment, 'content'>> & { content: TeaserFragment['content'] },
): TeaserFragment {
  return {
    deskedLabel: '',
    deskedIntro: '',
    deskedShowLiveIcon: false,
    deskedTitle: '',
    display: '',
    ...partialTeaserFragment,
  };
}

export function isArticleTeaserContent(content: unknown): content is ArticleTeaserContent {
  return (
    !!content &&
    typeof content === 'object' &&
    '__typename' in content &&
    typeof content.__typename === 'string' &&
    ['NewsArticle', 'Feature', 'Interview', 'Opinion', 'SponsoredArticle', 'VisualStory'].includes(content.__typename)
  );
}

export function isVisualStoryTeaserContent(content: unknown): content is VisualStoryTeaserFragment {
  return (
    !!content &&
    typeof content === 'object' &&
    '__typename' in content &&
    typeof content.__typename === 'string' &&
    content.__typename === 'VisualStory'
  );
}

export function isAudioVisualTeaserContent(content: unknown): content is AudioVisualTeaserContent {
  return (
    !!content &&
    typeof content === 'object' &&
    '__typename' in content &&
    typeof content.__typename === 'string' &&
    ['LiveVideo', 'Podcast', 'Video'].includes(content.__typename)
  );
}

export function isRealTeaserContent(content: unknown): content is RealTeaserContent {
  return isArticleTeaserContent(content) || isAudioVisualTeaserContent(content);
}
export function isAdditionalTeaserContent(content: TeaserFragment['content']): content is AdditionalTeaserContent {
  return isTagIntroTeaserContent(content) || isExternalLinkTeaserContent(content) || isExtraPageTeaserContent(content);
}

export function isArticleListType(content: unknown): content is ArticleList {
  return typeof content === 'object' && !!content && '__typename' in content && content.__typename === 'ArticleList';
}

export function isRssArticleListType(content: unknown): content is RssArticleList {
  return typeof content === 'object' && !!content && '__typename' in content && content.__typename === 'RssArticleList';
}

export function isArticleListContent(content: TeaserFragment['content']): content is OnArticleList {
  return !!content && content.__typename === 'ArticleList';
}

export function isNewsflowListType(content: unknown): content is NewsflowList {
  return typeof content === 'object' && !!content && '__typename' in content && content.__typename === 'Newsflow';
}

export function isNewsflowListContent(content: TeaserFragment['content']): content is OnNewsflowList {
  return content.__typename === 'Newsflow';
}

export function isPersonalizedArticleListContent(content: unknown): content is PersonalizedArticleList {
  return !!content && typeof content === 'object' && '__typename' in content && content.__typename === 'PersonalizedArticleList';
}

export function isMostReadArticleList(content: unknown): content is OnMostReadArticleList {
  return !!content && typeof content === 'object' && '__typename' in content && content.__typename === 'MostReadArticleList';
}

export function isTagIntroTeaserContent(content: TeaserFragment['content']): content is TagIntroTeaserContent {
  return !!content && ['TagIntro', 'TagIntroB2B'].includes(content.__typename);
}

export function isTagIntroB2BTeaserContent(content: TeaserFragment['content']): content is TagIntroB2bTeaserFragment {
  return !!content && content.__typename === 'TagIntroB2B';
}

export function isExternalLinkTeaserContent(content: TeaserFragment['content']): content is ExternalLinkTeaserFragment {
  return !!content && ['ExternalLink', 'ExternalLinkB2B'].includes(content.__typename);
}
export function isExtraPageTeaserContent(content: TeaserFragment['content']): content is ExtraPageTeaserFragment {
  return !!content && content.__typename === 'ExtraPage';
}

export function isB2bContent(content: TeaserFragment['content']): content is B2BTeaserContent {
  return !!content && ['TagIntroB2B', 'SponsoredArticle', 'ExternalLinkB2B'].includes(content.__typename);
}

export type HtmlBlockFragment = PickTypenamed<AtomLink> & {
  content: PickTypenamed<HtmlBlock> & {
    id: HtmlBlock['displayId'];
    name: HtmlBlock['fields']['name'];
    htmlBlock: HtmlBlock['fields']['htmlBlock'];
    type: string;
  };
};

export type RssListContentFragment = PickTypenamed<AtomLink> & {
  content: PickTypenamed<RssArticleList> & {
    id: RssArticleList['id'];
    title: RssArticleList['title'];
    fields: RssArticleList['fields'];
    logo:
      | [
          AtomLink & {
            content: PictureRelationFragment;
          },
        ]
      | [];
    content: RssArticleList['content'];
  };
};
